<!--
 * @Author: 侯怀烨
 * @Date: 2021-04-19 16:54:06
 * @LastEditTime: 2021-04-20 15:52:40
 * @LastEditors: Please set LastEditors
 * @Description: 开通用户
 * @FilePath: \sd-vue-admin\src\views\project\marketing\statistics\opening\components\OpenCustomers.vue
-->
<template>
  <div class="orderTest-container">
    <div id="myChart" style="width: 100%; height: 500px"></div>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    components: {},
    data() {
      return {
        url: {
          data: '/shopAdmin/register/count-day',
        },
        option: {
          title: {
            show: true,
            text: '开通客户数趋势图',
            left: 'center',
            textStyle: {
              color: '#277ace',
              fontSize: 24,
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: [
            {
              color: '#0685fc',
              type: 'category',
              data: [],
              axisTick: {
                alignWithLabel: true,
              },
              axisLabel: {
                interval: 0,
                textStyle: {
                  // color: '#556677',
                },
                // 默认x轴字体大小
                fontSize: 15,
                // margin:文字到x轴的距离
                margin: 15,
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
            },
          ],
          series: [
            {
              name: '当日开通',
              type: 'bar',
              barWidth: '60%',
              data: [],
              itemStyle: {
                normal: {
                  color: function (params) {
                    var colorList = ['#5d9cf9']
                    return colorList
                  },
                  label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}',
                    fontSize: 20,
                  },
                },
              },
            },
          ],
        },
      }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.handlergetdata()
    },
    methods: {
      handlergetdata() {
        postAction(this.url.data, {})
          .then((res) => {
            console.log(res, '开通用户')
            this.option.xAxis[0].data = res.data.map((list) => {
              return list.create_at
            })
            this.option.series[0].data = res.data.map((item) => {
              return item.count
            })
            console.log(this.option, '数据')
            this.myChartOne = this.$echarts.init(
              document.getElementById('myChart')
            )
            this.myChartOne.setOption(this.option)
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
