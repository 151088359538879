<!--
 * @Author: 侯怀烨
 * @Date: 2021-04-20 11:26:49
 * @LastEditTime: 2021-04-20 15:58:47
 * @LastEditors: Please set LastEditors
 * @Description: 累计开通
 * @FilePath: \sd-vue-admin\src\views\project\marketing\statistics\opening\components\Cumulative.vue
-->
<template>
  <div class="orderTest-container">
    <div id="Chart" style="width: 100%; height: 500px"></div>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    components: {},
    data() {
      return {
        url: {
          data: '/shopAdmin/register/count-month',
        },
        option: {
          title: {
            text: '累计开通客户数量',
            left: 'center',
            textStyle: {
              color: '#277ace',
              fontSize: 24,
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: [],
            },
          ],
          yAxis: [
            {
              type: 'value',
            },
          ],
          series: [
            {
              name: '用户数量',
              type: 'line',
              stack: '总量',
              smooth: true,
              showSymbol: false,
              emphasis: {
                focus: 'series',
              },
              areaStyle: {
                opacity: 0.05,
                color: '#5d9cf9',
              },
              itemStyle: {
                normal: {
                  color: function (params) {
                    var colorList = ['#5d9cf9']
                    return colorList
                  },
                  lineStyle: {
                    color: '#5d9cf9', //改变折线颜色
                  },
                  label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}',
                    fontSize: 20,
                  },
                },
              },
              data: [],
            },
          ],
        },
      }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      handlermonth() {
        postAction(this.url.data, {})
          .then((res) => {
            console.log(res)
            this.option.xAxis[0].data = res.data.map((list) => {
              return list.create_at
            })
            this.option.series[0].data = res.data.map((item) => {
              return item.count
            })
            console.log(this.option, '数据')
            this.myChartOne = this.$echarts.init(
              document.getElementById('Chart')
            )
            this.myChartOne.setOption(this.option)
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
