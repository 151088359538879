<!--
 * @Author: 候怀烨
 * @Date: 2021-03-04 14:56:18
 * @LastEditTime: 2021-04-20 16:15:58
 * @LastEditors: Please set LastEditors
 * @Description: 开通统计
 * @FilePath: \sd-vue-admin\src\views\project\marketing\statistics\opening\index.vue
-->
<!--  -->
<template>
  <div class="orderTest-container">
    <div class="bg">
      <div class="Thatday">
        <h3>今日开通：</h3>
        <span
          style="
            font-size: 100px;
            color: #0685fc;
            text-decoration: underline;
            cursor: pointer;
          "
          @click="getThatday"
        >
          {{ countnmb.today }}
        </span>
      </div>
      <div class="Grandtotal">
        <h3>累计开通：</h3>
        <span
          style="
            font-size: 100px;
            color: #0685fc;
            text-decoration: underline;
            cursor: pointer;
          "
          @click="getGrandtotal"
        >
          {{ countnmb.total }}
        </span>
      </div>
    </div>
    <div style="margin-top: 20px">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="开通客户数" name="first">
          <open-customers ref="open"></open-customers>
        </el-tab-pane>
        <el-tab-pane label="累计开通客户数" name="second">
          <cumulative ref="cumulative"></cumulative>
        </el-tab-pane>
        <el-tab-pane label="业务员榜单" name="third">
          <salespersonlist ref="list"></salespersonlist>
        </el-tab-pane>
      </el-tabs>
      <linkuser ref="user"></linkuser>
    </div>
  </div>
</template>

<script>
  import linkuser from './components/linkuser.vue'
  import Salespersonlist from './components/Salespersonlist.vue'
  import Cumulative from './components/Cumulative.vue'
  import OpenCustomers from './components/OpenCustomers.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      OpenCustomers,
      Cumulative,
      Salespersonlist,
      linkuser,
    },
    data() {
      return {
        activeName: 'first',
        url: {
          count: '/shopAdmin/register/count',
        },
        countnmb: {
          today: '0',
          total: '0',
        },
      }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.getcount()
    },
    methods: {
      handleClick(e) {
        console.log(e, '')
        switch (e.name) {
          case 'first':
            this.$refs.open.handlergetdata()
            break
          case 'second':
            this.$refs.cumulative.handlermonth()
            break
          case 'third':
            this.$refs.list.handlerlist()
            break
        }
      },
      getThatday() {
        this.$refs.user.show = true
        this.$refs.user.form.start_time = this.countnmb.end_time
        this.$refs.user.form.end_time = this.countnmb.end_time
        this.$refs.user.handlerlist()
      },
      getGrandtotal() {
        this.$refs.user.show = true
        this.$refs.user.form.start_time = this.countnmb.start_time
        this.$refs.user.form.end_time = this.countnmb.end_time
        this.$refs.user.handlerlist()
      },
      getcount() {
        postAction(this.url.count, {})
          .then((res) => {
            console.log(res)
            this.countnmb = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .bg {
    position: relative;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    width: 820px;
    height: 160px;
    background: #ffffff;
    border: 4px solid #0685fc;
    border-radius: 5px;
    box-shadow: #f1ecec;
  }
  .bg::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: '';
    border-top: 40px solid #0685fc;
    border-left: 40px solid transparent;
  }
  .bg::before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 5px;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    content: '√';
  }
  .Thatday {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .Thatday > h3 {
    color: rgb(8, 195, 252);
  }
  .Grandtotal > h3 {
    color: rgb(8, 195, 252);
  }
  .Grandtotal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>
