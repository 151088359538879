<!--
 * @Author: 侯怀烨
 * @Date: 2021-04-20 15:58:16
 * @LastEditTime: 2021-04-20 16:29:33
 * @LastEditors: Please set LastEditors
 * @Description: 开通详情
 * @FilePath: \sd-vue-admin\src\views\project\marketing\statistics\opening\components\linkuser.vue
-->
<template>
  <div class="orderTest-container">
    <el-dialog :modal="false" title="开通明细" center :visible.sync="show">
      <div>
        <el-form ref="form" :model="form" inline>
          <el-form-item label="开通时间" prop="start_time">
            <el-date-picker
              v-model="form.start_time"
              type="date"
              placeholder="选择开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="end_time">
            <el-date-picker
              v-model="form.end_time"
              type="date"
              placeholder="选择结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerInquire">查 询</el-button>
          </el-form-item>
        </el-form>
        <el-table stripe :data="tabledata">
          <el-table-column
            type="index"
            align="center"
            width="40"
          ></el-table-column>
          <el-table-column
            v-for="(list, index) in colemd"
            :key="index"
            :label="list.label"
            :align="list.align"
            :prop="list.prop"
            width=""
          ></el-table-column>
        </el-table>
        <el-row type="flex" class="row-bg" justify="end">
          <el-pagination
            background
            :current-page="form.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button>打 印</el-button>
        <el-button>导 出</el-button>
        <el-button @click="show = !show">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    components: {},
    data() {
      return {
        show: false,
        form: {
          start_time: '',
          end_time: '',
          pageNo: 1,
          pageSize: 10,
        },
        tabledata: [],
        url: {
          info: '/shopAdmin/register/detail',
        },
        colemd: [
          {
            label: '区域',
            align: 'center',
            prop: 'area',
            width: '',
          },
          {
            label: '客户名称',
            align: 'center',
            prop: 'cust_name',
            width: '',
          },
          {
            label: '绑定微信',
            align: 'center',
            prop: 'wechat',
            width: '',
          },
          {
            label: '开通人',
            align: 'center',
            prop: 'user_name',
            width: '',
          },
          {
            label: '开通时间',
            align: 'center',
            prop: 'create_at',
            width: '',
          },
        ],
        total: 0,
      }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      handlerInquire() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.info, this.form)
          .then((res) => {
            console.log(res, '')
            this.tabledata = res.data.rows
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
