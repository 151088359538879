var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderTest-container" }, [
    _c("div", { staticClass: "bg" }, [
      _c("div", { staticClass: "Thatday" }, [
        _c("h3", [_vm._v("今日开通：")]),
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "100px",
              color: "#0685fc",
              "text-decoration": "underline",
              cursor: "pointer",
            },
            on: { click: _vm.getThatday },
          },
          [_vm._v(" " + _vm._s(_vm.countnmb.today) + " ")]
        ),
      ]),
      _c("div", { staticClass: "Grandtotal" }, [
        _c("h3", [_vm._v("累计开通：")]),
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "100px",
              color: "#0685fc",
              "text-decoration": "underline",
              cursor: "pointer",
            },
            on: { click: _vm.getGrandtotal },
          },
          [_vm._v(" " + _vm._s(_vm.countnmb.total) + " ")]
        ),
      ]),
    ]),
    _c(
      "div",
      { staticStyle: { "margin-top": "20px" } },
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "card" },
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "开通客户数", name: "first" } },
              [_c("open-customers", { ref: "open" })],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "累计开通客户数", name: "second" } },
              [_c("cumulative", { ref: "cumulative" })],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "业务员榜单", name: "third" } },
              [_c("salespersonlist", { ref: "list" })],
              1
            ),
          ],
          1
        ),
        _c("linkuser", { ref: "user" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }