var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "orderTest-container" }, [
      _c("div", {
        staticStyle: { width: "100%", height: "500px" },
        attrs: { id: "Chart" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }