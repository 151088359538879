<!--
 * @Author: 侯怀烨
 * @Date: 2021-04-20 15:07:07
 * @LastEditTime: 2021-04-20 15:45:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\project\marketing\statistics\opening\components\Salespersonlist.vue
-->
<template>
  <div class="orderTest-container">
    <div id="Chartlist" style="width: 100%; height: 600px"></div>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    components: {},
    data() {
      return {
        url: {
          data: '/shopAdmin/register/user-top',
        },
        option: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: [],
            axisLabel: {
              interval: 0,
              textStyle: {
                // color: '#556677',
              },
              // 默认x轴字体大小
              fontSize: 15,
              // margin:文字到x轴的距离
              margin: 15,
            },
          },
          series: [
            {
              name: '累计开通',
              type: 'bar',
              stack: 'total',
              label: {
                show: true,
              },
              emphasis: {
                focus: 'series',
              },
              data: [],
            },
          ],
        },
      }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      handlerlist() {
        console.log(this.url.data, '')
        postAction(this.url.data, {})
          .then((res) => {
            console.log(res)
            this.option.yAxis.data = res.data
              .map((list) => {
                return list.user_name
              })
              .reverse()
            this.option.series[0].data = res.data
              .map((item) => {
                return item.count
              })
              .reverse()
            console.log(this.option, '数据')
            this.myChartOne = this.$echarts.init(
              document.getElementById('Chartlist')
            )
            this.myChartOne.setOption(this.option)
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
